import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageBecomeASitter = (props) => {
  return (
    <Template>
      <div className={style.pageContainer}>
        <Helmet>
          <title>Become a sitter - {props.data.site.siteMetadata.title}</title>
        </Helmet>
        <div className={style.heroContainer}>
          <h1>Join as a business</h1>
          <p className={style.subline}>
            More than 50,000 dog owners browse doggos.com every month. Get
            discovered with a free account
          </p>
          <div className={style.buttonContainer}>
            <Link to='/add-business' className={style.buttonSubmit}>
              Create account
            </Link>
            <a href='#platform_benefits' className={style.button}>
              Learn more
            </a>
          </div>
        </div>

        <div className={style.makeTheSwitch}>
          <div className={style.copy}>
            <h2>Make the switch</h2>
            <p>
              Doggos.com is the only platform that is{' '}
              <strong>
                <span className={style.decorativeText}>completely FREE</span>
              </strong>{' '}
              to use for pet businesses
            </p>
            <p>No monthly fees, hidden costs contracts, or commissions</p>
          </div>
          <div>
            <StaticImage
              draggable='false'
              placeholder='blurred'
              src={'./dog-sitter-1.png'}
              alt='Image of a young woman sitting on a sofa petting a dog.'
              className={style.coverPhoto}
            />
          </div>
        </div>

        <div className={style.platformBenefits}>
          <h2 id='platform_benefits'>Platform benefits</h2>

          <div className={style.textSection}>
            <div className={style.textBlockContainer}>
              <div className={style.textBlock}>
                <h3 className={style.h3}>Veterinary reimbursement</h3>
                <p>
                  A minimum of $2,500 veterinary care reimbursement is included
                  with every reservation paid via doggos.com, even if your
                  business already has insurance
                </p>
              </div>
              <div className={style.textBlock}>
                <h3 className={style.h3}>
                  <span className={style.textHighlight}>0 fees</span>, payouts
                  as soon as 24 hours
                </h3>
                <p>
                  Doggos.com is free to use for pet professionals, with payments
                  eligible to be paid out as soon as 24 hours after a completed
                  reservation
                </p>
              </div>
              <div className={style.textBlock}>
                <h3 className={style.h3}>Free advertising</h3>
                <p>
                  We advertise in the cities we're in, our newsletter, and on
                  high-visibility pages like our search and articles, completely
                  free to you
                </p>
              </div>
              <div className={style.textBlock}>
                <h3 className={style.h3}>Cash payments and point of sale</h3>
                <p>
                  Accept cash payments, POS, or checks? No problem. We allow a
                  variety of payment options designed to meet the needs of pet
                  industry professionals
                </p>
              </div>
              <div className={style.textBlock}>
                <h3 className={style.h3}>Ongoing support</h3>
                <p>
                  We work directly with pet professionals to make sure they're
                  getting the best out of the platform
                </p>
              </div>
              <div className={style.buttonContainer}>
                <Link to='/add-business' className={style.buttonSubmit}>
                  Create account
                </Link>
              </div>
            </div>
          </div>
          <div className={style.platformFeatures}>
            <h2>Business features</h2>
            <div className={style.calendar}>
              <h2>Custom calendar</h2>
              <p>
                Our reservation calendar works on any device. Easily track and
                schedule dogs, manage your availability, and set custom prices
              </p>
              <StaticImage
                draggable='false'
                placeholder='blurred'
                src={'./image1.png'}
                alt='Image of a booking calendar interface'
              />
            </div>
            <div className={style.chat}>
              <h2>Live chat</h2>
              <p>
                Accept or deny reservations easily with our chat. Communicate
                with dog owners, send video and photo updates, process payments,
                or request a meeting
              </p>
              <StaticImage
                draggable='false'
                placeholder='blurred'
                src={'./image2.png'}
                alt='Image of a chat interface'
                className={style.image}
              />
            </div>
            <div className={style.profiles}>
              <div className={style.content}>
                <h2>Dog profiles</h2>
                <p>
                  Our beautiful free dog profile pages help you find dogs that
                  are a perfect match for your services
                </p>
                <div className={style.buttonContainer}>
                  <Link to='/add-business' className={style.buttonSubmit}>
                    Create account
                  </Link>
                </div>
              </div>
              <div className={`${style.image} ${style.hiddenOnMobile}`}>
                <StaticImage
                  draggable='false'
                  placeholder='blurred'
                  src={'./image3.png'}
                  alt='Stylized images of a black German Shepherd'
                />
              </div>
            </div>
            <div className={style.getStarted}>
              <h2>Get started today</h2>
              <p>
                We're always working on new features and improvements and would
                love to hear your feedback! Message @doggos in the chat anytime
                to ask questions or get help
              </p>
              <div className={style.buttonContainer}>
                <Link to='/add-business' className={style.buttonSubmit}>
                  Create account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default PageBecomeASitter
